import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import React, { useEffect, useState } from "react";

// Put your API key here
builder.init('236a0020faca4be2a26218e99d3c91c0');

function Page() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(undefined);

  // get the page content from Builder
  useEffect(() => {
    async function fetchContent() {
      const content = await builder.get('page', { url: '/landing', entry: '4a41366f8ff74737b9f60b83c161bf02' })
        .promise();

      setContent(content);
      setNotFound(!content);
    }
    fetchContent();
  }, []);
  // if no page is found, return a 404 page
  if (notFound && !isPreviewingInBuilder) {
    return <div>Not Found 404 Error</div>;
  }

  // return the page when found
  return (
    <>
      {/* Render the Builder page */}
      <BuilderComponent model="page" content={content} />
    </>
  );
}

export default Page;