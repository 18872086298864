import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";

import 'bootstrap/dist/css/bootstrap.min.css';

const firebaseConfig = {
  apiKey: "AIzaSyD3fva9nNyhBWgMlkfeJZtWn5icWCIji7k",
  authDomain: "siyathenda-landing.firebaseapp.com",
  projectId: "siyathenda-landing",
  storageBucket: "siyathenda-landing.appspot.com",
  messagingSenderId: "461790676714",
  appId: "1:461790676714:web:39cd9f77b3d4c800b63993",
  measurementId: "G-3J9EX7PRQJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
initializeAnalytics(app);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
